@media (max-width: 1280px) {
 .section-e3-child {
      width: 90%;
 }
}

@media (max-width: 767px) {
  .section-e3-select {
    width: 100%;
  }
}

