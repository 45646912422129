// Dark mode CSS

.theme-dark {
  [id^="d7-carousel-block_"] {
    background-color: #1C1C1E;

    .script-heading, .main-heading, .notes, p {
      color: #FFFFFF;
    }

    .items-info {
      div, p {
        color: #FFF;
      }
    }
  }
}
