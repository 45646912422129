@import "/resources/scss/theme/base";

.block__f2-quote-b {
  &.bg-color-dark-grey {
    p {
      color: #fff;
    }
  }
  .block__f2-quote-b__row {
    @media only screen and (max-width: $mobile-landscape) {
      > * + * {
        margin-top: rem(40);
      }
    }
  }
  .content_block {
    max-width: 800px;
    text-align: center;

    .paragraph {
      font-size: rem(16);
      line-height: rem(22);
    }

    .author,
    .points {
      margin: 0 0 12px 0;
      font-size: rem(12);
      color: $gold;
      line-height: rem(12);
      letter-spacing: 1.44px;
      font-weight: 600;
      text-transform: uppercase;

      &.stronger {
        font-weight: 900;
      }
    }

    .star_rating {
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        width: 23px;
        height: 23px;
        margin-right: 3px;
        background-image: url("../resources/icons/star-inactive.svg");
        display: inline-block;
        background-position: center;
        background-repeat: no-repeat;

        &.active {
          background-image: url("../resources/icons/star-active.svg");
        }
      }
    }
  }
}
