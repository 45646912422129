#single-product-page {
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent !important;
    background-image: url("../resources/images/Arrow-Icon.png") !important;
    background-repeat: no-repeat !important;
    background-position-x: 100% !important;
    background-position-y: 9px !important;
    background-size: 14px !important;
    font-size: 12px;
    text-transform: uppercase;
  }
}

.wine_available_error {
  color: #8F1920 !important;
  font-size: 14px;
  font-weight: bold;

  a {
    text-decoration: underline;
    color: #8F1920 !important;
  }
}

// Dark mode CSS

.theme-dark {
  #single-product-page {
    select {
      -webkit-appearance: none;
      -moz-appearance: none;
      background-color: #1c1c1e !important;
      background-image: url("../resources/images/Arrow-Icon-White.png") !important;
      background-repeat: no-repeat !important;
      background-position-x: 100% !important;
      background-position-y: 9px !important;
      background-size: 14px !important;
      font-size: 12px;
      text-transform: uppercase;
      color: #FFFFFF !important;
    }
  }
}
