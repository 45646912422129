.block__text-block {
  ul,
  ol {
    margin: 0 0 rem(20) rem(20);
    line-height: rem(20);
  }

  h3 {
    margin-bottom: rem(60);
  }
}
