.block__recipe-text-block {
  ol.preparation-steps {
    margin: 0 0 2rem 0;
    padding: 0;
    counter-reset: item;
    line-height: 1.4rem;

    & > li {
      padding: 0;
      text-indent: 0;
      list-style-type: none;
      counter-increment: item;
      margin: 0 0 2rem;

      &:before {
        display: inline-block;
        width: 2rem;
        padding-right: 1.6rem;
        font-weight: 700;
        text-align: right;
        content: counter(item) ".";
      }
    }
  }

  h2 {
    font-size: 2rem;

    @media only screen and (max-width: $desktop) {
      font-size: 1.6rem;
    }
  }

  h3 {
    margin-bottom: 60rem;
  }
}
