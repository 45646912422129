// Dark mode CSS

.theme-dark {
  [id^="d1-banner-2assets-text-centered-block_"] {
    background-color: #212121;

    .script-heading, .main-heading, .notes, p {
      color: #FFFFFF;
    }

    .site-button {
      background: #AD9B5C;
      color: #222221;
    }
  }
}
