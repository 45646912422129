#page-foliage,
.block-foliage {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 20;
  width: 100vw;
  height: 100%;
  pointer-events: none;

  .foliage {
    position: fixed;
    top: 0;
    transform: scale(0.5);

    &.left {
      transform-origin: left top;
      left: 0;

      img {
        transform: scaleX(-1) translateX(100%);
        transition: transform 1s;
      }
    }

    &.right {
      transform-origin: right top;
      right: 0;

      img {
        transform: translateX(100%);
        transition: transform 1s;
      }
    }
  }

  &.show {
    .left {
      img {
        transform: scaleX(-1) translateX(0);
      }
    }

    .right {
      img {
        transform: translateX(0);
      }
    }
  }
}
