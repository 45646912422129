.block__d12-banner {
  &__content {
    display: flex;
    flex-direction: column;
    padding: 8px 14px 24px;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.15);
    margin-top: 15px;

    @media only screen and (min-width: $phablet) {
      padding: 16px 24px 32px;
    }
    @media only screen and (min-width: $base) {
      flex: 50% 50%;
      flex-direction: row;
      padding: 30px;
    }

    .asset_block_main {
      overflow: hidden;
      @media only screen and (min-width: $base) {
        flex: 0 0 50%;
      }

      img {
        transition: scale 400ms;
        object-fit: cover;
        height: 100%;
        width: 100%;
      }

      &:hover {
        img {
          scale: 1.03;
          transition: scale 400ms ease-out;
        }
      }
    }

    .content_block {
      align-self: center;
      align-items: flex-start;
      padding: 20px 10px 0px 0px;

      @media only screen and (min-width: $tablet) {
        padding: 40px 40px 0px 0px;
      }
      @media only screen and (min-width: $base) {
        padding: 40px 20px 40px 40px;
      }

      @media only screen and (min-width: $desktop) {
        padding: 40px 60px 40px 100px;
      }

      .script-heading {
        font-size: 2rem;
        line-height: 2rem;

        @media only screen and (min-width: $base) {
          font-size: 2.5rem;
          line-height: 2.5;
        }
        @media only screen and (min-width: $desktop) {
          font-size: 3rem;
          line-height: 3rem;
        }
      }

      .main-heading {
        margin-bottom: rem(20);
        font-size: 1.865rem;
        line-height: 1.865rem;

        @media only screen and (min-width: $base) {
          margin-bottom: rem(25);
          font-size: 2.5rem;
          line-height: 2.5rem;
        }
        @media only screen and (min-width: $desktop) {
          margin-bottom: rem(15);
          font-size: 3.16rem;
          line-height: 3.16rem;
        }
      }

      .short-description {
        margin-bottom: rem(20);
        line-height: rem(20);

        @media only screen and (min-width: $desktop-hidpi) {
          margin-bottom: rem(25);
        }
        @media only screen and (max-width: $mobile-landscape) {
          margin-bottom: rem(15);
        }
      }

      .ctas {
        @media only screen and (max-width: $mobile-landscape) {
          align-items: flex-start;
        }
      }
      .primary-cta {
        .site-link {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          color: $white;
          &:hover {
            color: $gold;
          }
        }
      }

      .secondary-cta {
        .site-link {
          color: $link-color;
          &:hover {
            color: $link-color-hover;
          }
        }
      }
    }
  }
}
