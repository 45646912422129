.block__masthead-article {
  a[target="_blank"] {
    position: relative;
    padding-right: 11px;

    &::after {
      content: "";
      position: absolute;
      width: 8px;
      height: 8px;
      background-image: url("../resources/icons/external-link-icon.svg");
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .asset_block_main {
    min-height: 473px;
    background-color: red;

    img {
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 100%;
    }

    @media only screen and (max-width: $desktop) {
      min-height: 400px;
    }

    @media only screen and (max-width: $tablet) {
      // min-height: 00px;
    }

    @media only screen and (max-width: $mobile) {
      min-height: unset;
    }
  }
}
