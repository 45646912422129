@import "/resources/scss/theme/base";

.block__masthead-ranges {
  min-height: rem(540);

  .wine-bottle {
    max-height: 540px;
    img {
      object-fit: contain;
      object-position: center;
      width: 100%;
      height: 100%;
    }
  }

  &.bg-chevron-full- {
    background-image: url("../resources/images/chevron-white.svg");
    background-position: right -200px top;
    background-repeat: no-repeat;
    background-size: 100%;
    background-color: $light-grey-bg;

    .wine-bottle {
      grid-column-start: 7;
      @media only screen and (max-width: $mobile-landscape) {
        grid-column-start: unset;
      }
    }
  }

  &.bg-chevron-half-left {
    background-image: url("../resources/images/chevron-left.svg");
    background-position: top left -18%;
    background-repeat: no-repeat;
    background-size: 60%;
    background-color: $white;

    .content_block {
      grid-column-start: 7;
      order: 2;
    }
    .wine-bottle {
      order: 1;
    }
  }

  &.bg-chevron-half-right {
    background-image: url("../resources/images/chevron-right.svg");
    background-position: top right -18%;
    background-repeat: no-repeat;
    background-size: 60%;
    background-color: $white;
    .content_block {
    }
    .wine-bottle {
      grid-column-start: 7;
    }
  }

  &.bg-chevron-half-left {
    .content_block {
      grid-column: auto/span 5;
    }
  }

  &.bg-chevron-half-right {
    .content_block {
      grid-column: auto/span 5;
    }
  }

  @media only screen and (min-width: $base + 1) {
    min-height: 40vh;
    &.bg-chevron-full- {
      background-position: right -220px top;
    }
  }
  @media only screen and (min-width: $desktop + 1) {
    min-height: 40vh;
    &.bg-chevron-full- {
      background-position: right -300px bottom 200px;
    }
  }

  @media only screen and (min-width: $desktop-large + 1) {
    min-height: 60vh;
    &.bg-chevron-full- {
      background-position: right -300px bottom 200px;
    }
  }
  @media only screen and (min-width: $desktop-hidpi + 1) {
    &.bg-chevron-full- {
      background-position: right -500px bottom 200px;
    }
  }

  @media only screen and (max-width: $mobile-landscape) {
    background-size: 180%;
    background-position: center top;

    .wine-bottle {
      order: 0;
    }

    .content_block {
      order: 1;
    }

    &.bg-chevron-full- {
      background-size: 250%;
      background-position: center top -200px;
    }
    &.bg-chevron-half-right,
    &.bg-chevron-half-left {
      background-size: 140%;
      background-position: left 25% top;
      .content_block,
      .wine-bottle {
        grid-column-start: unset;
      }
      .content_block {
        grid-column: 1/-1;
      }
    }
  }

  @media only screen and (max-width: $mobile) {
    &.bg-chevron-full- {
      background-position: center top -100px;
    }
  }

  @media only screen and (max-width: $mobile) {
    &.bg-chevron-full- {
      background-position: center top -100px;
    }
    &.bg-chevron-half-left {
      .content_block {
        grid-column: 1/-1;
      }
    }
  }

  &__container__row {
    .content_block {
      align-items: flex-start;
      justify-content: center;

      .main-heading {
        margin-bottom: rem(20);
        @media only screen and (min-width: $base + 1) {
          font-size: rem(70);
          line-height: rem(69);
        }
        @media only screen and (max-width: $mobile-landscape) {
          font-size: rem(38);
          line-height: rem(38);
          margin-bottom: rem(15);
        }
      }

      p {
        margin-bottom: rem(15);
      }
    }
  }
}

// Dark mode CSS

.theme-dark {
  [id^="masthead-ranges-block_"] {
    background-color: #212121 !important;

    .script-heading,
    .main-heading,
    .notes,
    p {
      color: #ffffff;
    }
  }

  .block__masthead-ranges.bg-chevron-full- {
    background-image: url("../resources/images/chevron-black.png") !important;
  }
}
