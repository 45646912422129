.block__card-blog {
  position: relative;
  background: linear-gradient(0deg, #f5f5f5 50%, rgba(255, 255, 255, 0) 50%);
}

.blog_listing_hl {
  position: relative;
  display: flex !important;
  margin: 0;
  padding: 0;
  max-height: 460px;
  background-color: white;
  padding: 20px;

  @media (max-width: 770px) {
    display: block !important;
    max-height: unset;
    padding: 20px;
  }

  .blog_hl_image_link {
    position: relative;
    width: 50% !important;
    padding: 0 !important;
    margin: 0 !important;
    height: 100% !important;

    @media (max-width: 770px) {
      width: 100% !important;
      height: auto !important;
    }
  }

  .blog_hl_image {
    position: relative;
    width: 100% !important;
    // height: 100% !important;
    min-height: 400px !important;
    max-height: 460px !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
  }

  .blog_hl_info {
    position: relative;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 770px) {
      width: 100% !important;
    }

    .blog_hl_info_content {
      padding: 40px;

      @media (max-width: 770px) {
        padding: 0;
      }
    }

    h1 {
      margin-bottom: 10px !important;
    }

    p {
      margin-bottom: 10px !important;
    }

    .site-link {
      margin-top: 0;
    }
  }

  .asset_block_main {
    height: 100%;

    a,
    img {
      height: 100%;
    }
  }
}
