.block__a7-fullbleed-vid-img {
  position: relative;
  background-color: $dark-grey-bg;

  .video-background {
    position: absolute;
    overflow: hidden;
    width: 100vw;
    height: 100%;
    left: 0;
    top: 0;
  }

  .video-background iframe {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100vw;
    height: 100%;
    transform: translate(-50%, -50%);
  }

  @media (min-aspect-ratio: 16/9) {
    .video-background iframe {
      /* height = 100 * (9 / 16) = 56.25 */
      height: 56.25vw;
    }
  }
  @media (max-aspect-ratio: 16/9) {
    .video-background iframe {
      /* width = 100 / (9 / 16) = 177.777777 */
      width: 177.78vh;
    }
  }

  &.has__overlay {
    min-height: 100vh;

    @media only screen and (max-width: $mobile-landscape) {
      min-height: 50vh;
    }
    .asset_container {
      position: absolute;
      height: 100%;
      left: 0;
      top: 0;

      &::after {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.4);
        z-index: 1;
      }
    }
  }

  .asset_container {
    overflow: hidden;
    z-index: 1;
    width: 100%;
    max-height: 100vh;

    img {
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 100%;
    }
  }

  .content_block {
    display: flex;
    align-items: center;
    flex-direction: column;
    z-index: 2;
    max-width: 600px;

    * {
      color: $white;
      text-align: center;
    }

    .main-heading {
      font-size: rem(42);
      line-height: rem(42);
      margin-bottom: rem(20);

      @media only screen and (min-width: $base) {
        font-size: rem(55);
        line-height: rem(60);
      }

      @media only screen and (min-width: $desktop-large) {
        font-size: rem(70);
        line-height: rem(70);
      }
    }

    .script-heading {
      font-size: rem(30);
      line-height: rem(40);

      @media only screen and (min-width: $base) {
        font-size: rem(38);
        line-height: rem(55);
      }

      @media only screen and (min-width: $desktop-large) {
        font-size: rem(42);
        line-height: rem(60);
      }
    }

    p {
      margin-bottom: rem(20);
    }

    .watch-video-btn {
      border: 0;
      padding-right: rem(28);
      font-size: rem(12);
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: rem(1.44);
      line-height: rem(20);
      position: relative;
      background: transparent;

      &::after {
        width: rem(20);
        height: rem(20);
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        background: url("../resources/icons/play-icon.svg") no-repeat center;
      }
      &:hover::after {
        background: url("../resources/icons/play-icon-hover.svg") no-repeat center;
      }
    }
  }
}

#videoPopup {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;

  #popupVideoContainer {
    width: 80%;
    aspect-ratio: 16 / 9;
    position: relative;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

#closePopup {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: $white;
  font-size: rem(30);
  font-weight: 300;
  width: rem(30);
  height: rem(30);
  background: url("../resources/icons/exit-btn.svg");
  background-size: cover;
}
