@import '/resources/scss/theme/base';
@import '/resources/scss/theme/functions';

.block__masthead-home {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1440px;
  height: 50vw;
  margin: 0 auto;

  @media only screen and (min-width: 1440px) {
    max-height: 800px;
  }

  @media only screen and (max-width: 800px) {
    height: 60vw;
  }

  @media only screen and (max-width: 600px) {
    height: auto;
  }

  .video_wrapper {
    position: absolute;
    top: 0;
    right: -260px;
    width: 100%;
    z-index: 1;

    @media only screen and (max-width: $base) {
      right: -200px;

      .header__logo {
        .light {
          opacity: 1;
        }
        .dark {
          opacity: 0;
        }
      }
    }

    .video_container {
      position: relative;
      aspect-ratio: 16 / 9;
      width: 100%;
      height: auto;
      overflow: hidden;

      display: flex;
      align-items: top;
      justify-content: center;

      &::after {
        content: '';
        position: absolute;
        width: calc(100% - 40px);
        height: 100%;
        left: 20px;
        top: 0;

        background-size: 90% 50%;
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0.6) 0%,
          rgba(0, 0, 0, 0.1) 25%,
          rgba(0, 0, 0, 0) 50%,
          rgba(0, 0, 0, 0) 95%,
          rgba(0, 0, 0, 0) 100%
        );
      }
    }

    video {
      position: relative;
      object-fit: contain;
      object-position: top center;
      width: 80%;
      height: auto;
      display: block;
    }

    .chevron {
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-image: url('../resources/images/hp-chevron.svg');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: top center;
      opacity: 0.5;

      &::before {
        content: ' ';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100vw;
        height: 200px;
        // background-color: red;
      }
    }

    .hp-wine-bottle {
      position: absolute;
      aspect-ratio: 3 / 5;
      bottom: -5%;
      right: 40.7%;
      z-index: 99;
      max-width: 26%;
    }
  }

  .content_block {
    width: 55%;
    max-width: 420px;
    margin-top: 80px;
  }

  @media only screen and (max-width: 600px) {
    .video_wrapper {
      position: absolute;
      top: 0;
      right: unset;
      left: -20%;
      width: 140%;
      z-index: 1;
      overflow-x: hidden;

      .hp-wine-bottle {
        bottom: -5%;
        right: 41%;
        z-index: 99;
        max-width: 26%;
      }
    }

    .content_block {
      margin-top: 80vw;
      width: 100%;
      max-width: 100%;
    }
  }

  &__container {
    .content_block {
      // align-items: flex-start;
      // z-index: 9;
    }
  }
}

.homepage-hero-text {
  z-index: 3;
}

#maroon-chev {
  fill: url(#Gradient1);
}
