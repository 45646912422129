@import "/resources/scss/theme/base";

.block__a10-full-bleed-image-carousel {
  position: relative;
  text-align: center;

  .asset_container {
    position: relative;
    z-index: 1;
    width: 100vw;
    height: 894px;
    overflow: hidden;

    @media only screen and (max-width: $mobile) {
      height: 80vh;
      max-height: 300px;
      margin: 20px 0;
    }
    @media only screen and (max-width: $tablet) {
      max-height: 500px;
      margin: 40px 0;
    }

    .splide {
      height: 100%;

      .splide__track {
        width: 100%;
        height: 100%;
      }

      ul.splide__list {
        position: absolute;
        width: 100%;
        height: 100%;

        li.splide__slide {
          position: relative;
          background-size: cover;
          background-position: 50% 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 2;
          width: 100%;
        }
      }
    }

    .splide__arrows {
      position: absolute;
      bottom: 17px;
      width: 100%;
      filter: invert(100%);
    }
  }

  &::after {
    bottom: 18% !important;
    @media only screen and (min-width: $mobile-landscape + 1) {
      bottom: 50%;
    }
  }
}
