// Dark mode CSS

.theme-dark {
  [id^="e1-tiles-wine-product-tiles-with-range-varietal-subheading-block_"] {
    background-color: #212121 !important;

    .script-heading, .main-heading, .notes, p {
      color: #FFFFFF;
    }

    .items-info {
      div, p {
        color: #FFF;
      }
    }
    .wine-tile-wrapper:hover {
      background-image: url("../resources/images/product-bg-sash-big-black.png") !important;
    }
  }
}
