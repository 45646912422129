@import "/resources/scss/theme/base";

.block__a7-fullbleed-vid-img {
  position: relative;

  .video-background {
    position: absolute;
    overflow: hidden;
    width: 100vw;
    height: 100%;
    left: 0;
    top: 0;
  }

  .video-background iframe {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100vw;
    height: 100%;
    transform: translate(-50%, -50%);
  }

  @media (min-aspect-ratio: 16/9) {
    .video-background iframe {
      /* height = 100 * (9 / 16) = 56.25 */
      height: 56.25vw;
    }
  }
  @media (max-aspect-ratio: 16/9) {
    .video-background iframe {
      /* width = 100 / (9 / 16) = 177.777777 */
      width: 177.78vh;
    }
  }

  &.has__overlay {
    min-height: 100vh;

    @media only screen and (max-width: $mobile-landscape) {
      min-height: 50vh;
    }
    .asset_container {
      position: absolute;
      height: 100%;
      left: 0;
      top: 0;

      &::after {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.4);
        z-index: 1;
      }
    }
  }

  .asset_container {
    overflow: hidden;
    z-index: 1;
    width: 100%;
    max-height: 100vh;

    img {
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 100%;
    }
  }

  .content_block {
    display: flex;
    align-items: center;
    flex-direction: column;
    z-index: 2;

    * {
      color: $white;
      text-align: center;
    }

    &.width-600 {
      max-width: 600px;

      .main-heading {
        font-size: rem(42);
        line-height: rem(42);
        margin-bottom: rem(20);

        @media only screen and (min-width: $base) {
          font-size: rem(55);
          line-height: rem(60);
        }

        @media only screen and (min-width: $desktop-large) {
          font-size: rem(70);
          line-height: rem(70);
        }
      }

      .script-heading {
        font-size: rem(30);
        line-height: rem(40);

        @media only screen and (min-width: $base) {
          font-size: rem(38);
          line-height: rem(55);
        }

        @media only screen and (min-width: $desktop-large) {
          font-size: rem(42);
          line-height: rem(60);
        }
      }

      p {
        margin-bottom: rem(20);
      }
    }
    &.width-800 {
      max-width: 800px;

      .script-heading {
        font-size: rem(40);
        line-height: rem(46);

        @media only screen and (min-width: $tablet) {
          font-size: rem(70);
          line-height: rem(80);
        }

        @media only screen and (min-width: $base) {
          font-size: rem(60);
          line-height: rem(80);
        }

        @media only screen and (min-width: $desktop-large) {
          font-size: rem(80);
          line-height: rem(92);
        }
      }

      p {
        font-size: rem(12);
        letter-spacing: rem(1.44);
      }
    }
  }
}
