// Dark mode CSS

.theme-dark {
  [id^="d1-banner-2assets-block_"] {
    background-color: #1c1c1e;

    .script-heading, .main-heading, .notes, h5, p {
      color: #FFFFFF;
    }

    a h5 {
      color: #FFFFFF !important;
    }

    .splide__slide.title-animation:hover .carousel_info a h5 {
      color: #AD9B5C;
    }
  }
}
