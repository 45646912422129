@import "/resources/scss/theme/base";
.block__card-blog {
  &__row {
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.15);
    margin-top: 15px;
    @media only screen and (min-width: $mobile-landscape + 1) {
      margin-inline: rem(-26);
      width: calc(100% + rem(52));
    }
    .asset_block_main {
      overflow: hidden;
      img {
        transition: scale 400ms;
      }
      &:hover {
        img {
          scale: 1.03;
          transition: scale 400ms ease-out;
        }
      }
    }
    .content_block {
      align-self: center;
      align-items: flex-start;
      .main-heading {
        @media only screen and (min-width: $base) {
          font-size: rem(45);
          line-height: rem(45);
        }
        @media only screen and (min-width: $desktop-hidpi) {
          font-size: rem(55);
          line-height: rem(55);
        }
        @media only screen and (max-width: $mobile-landscape) {
          font-size: rem(28);
          line-height: rem(28);
        }
      }

      p:not(.category-heading) {
        margin-bottom: rem(15);
      }
      .published-text {
        color: #626262;
        @media only screen and (max-width: $mobile) {
          font-size: rem(16);
          line-height: rem(22);
        }
      }
      .short-description {
        line-height: rem(20);
      }
    }
  }
}

.blog_listing {
  .block__c2-masthead-landing-pages {
    &.bg-bottom-right::after {
      bottom: 40%;
      @media only screen and (min-width: $mobile-landscape + 1) {
        bottom: -25%;
      }
    }
  }
}
