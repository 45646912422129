.stockist-name
{
  color: #000;
  font-feature-settings: 'liga' off;
  font-family: Bigola Display;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 98%; /* 23.52px */
}

.stockist-cta {
  display: flex;
  align-items: center;
  gap: 10px;

  a {
    color: #8F1920;
    font-family: Avenir Next;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    letter-spacing: 1.44px;
    text-transform: uppercase;
  }
}