.section-d6.bg-img-citrus::after {
  bottom: -10em;
  left: 0;
  transform: rotate(180deg);
}

@media (max-width: 767px) {
  .section-d6-row {
    width: 90%;

    & .section-d6-row-left,
    & .section-d6-row-right {
      width: 100% !important;
    }
  }
}

@media (max-width: 1280px) {
  .section-d6-row {
    width: 90%;

    & .section-d6-row-left {
      align-items: center !important;
    }

    & .section-d6-row-left,
    & .section-d6-row-right {
      width: 100% !important;
      margin: 2rem 0;
    }
  }
}

@media (min-width: 1280px) {
  .section-d6 .script-heading {
    font-size: 42px !important;
  }

  .section-d6 .main-heading {
    font-size: 70px !important;
  }

  .section-d6-row {
    width: 80%;
    flex-direction: row !important;

    & .section-d6-row-left,
    & .section-d6-row-right {
    }
  }
}

.block6_section-wrapper {
  background: #F5F5F5;
  overflow: unset !important;
  padding: 5em 0 !important;

}
