.timeline-block {
  position: relative;
  padding: 80px 0 0 0;

  .tl-container {
    position: relative;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 1296px;

    @media (max-width: 860px) {
      position: relative;
      width: 100%;
    }
  }

  .tl-entry {
    position: relative;
    width: 50%;
    display: flex;
    transform: translateY(100px);
    transition: transform 0.5s;

    img {
      transition: transform 0.65s;
    }

    &.show {
      transform: translateY(0);

      .wp-block-year {
        opacity: 1;
      }

      .wp-block-heading {
        opacity: 1;
      }

      .wp-block-description {
        opacity: 1;
      }

      img {
        transform: translateX(0) !important;
      }
    }

    .tl-entry-content {
      position: relative;
      max-width: 488px;
      width: 100%;
    }

    &.spacer {
      height: 200px !important;
    }

    &.left {
      justify-content: left;
      margin: -100px 0 200px 0;
      padding-left: 80px;
      padding-right: 80px;

      img {
        transform: translateX(-100%);
      }
    }

    &.right {
      margin-top: 100px;
      padding-right: 80px;
      padding-left: 80px;
      justify-content: right;

      img {
        transform: translateX(100%);
      }

      &.top {
        margin-top: 0 !important;
      }
    }

    .wp-block-year {
      font-size: 32px !important;
      font-family: Bigola Display !important;
      margin: 0;
      padding: 0;
      color: rgba(0, 0, 0, 0);
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: black;
      opacity: 0;
      transition: opacity 0.5s;

      @media (max-width: 620px) {
        font-size: 24px !important;
        line-height: 24px !important;
      }
    }

    .wp-block-heading {
      font-size: 45px !important;
      font-weight: 400 !important;
      font-family: Bigola Display !important;
      line-height: 45px !important;
      opacity: 0;
      transition: opacity 0.5s;
      margin-bottom: 10px;

      @media (max-width: 620px) {
        font-size: 28px !important;
        line-height: 28px !important;
      }
    }

    .wp-block-description {
      color: #222221 !important;
      font-family: Avenir Next !important;
      font-size: 16px !important;
      font-style: normal !important;
      font-weight: 400 !important;
      margin-bottom: 26px;
      // line-height: 140% !important;
      opacity: 0;
      transition: opacity 0.5s;
    }

    .frame {
      margin-top: 1em !important;
      height: 30 0px;
      width: 100%;
      max-height: 360px;

      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
    }

    img {
      object-fit: cover;
      flex-shrink: 0;
      min-width: 100%;
      min-height: 100%;
    }

    @media (max-width: 860px) {
      display: block;
      width: 100%;

      &.spacer {
        display: none;
      }

      &.left {
        justify-content: left;
        // margin: -100px 0 200px 0;
        // margin: 0;
        margin: 80px 0 0 0;
        padding-left: 180px;
        padding-right: 80px;
      }

      &.right {
        justify-content: left;
        // margin: -100px 0 200px 0;
        // margin: 0;
        margin: 80px 0 0 0;
        padding-left: 180px;
        padding-right: 80px;
      }
    }

    @media (max-width: 620px) {
      display: block;
      width: 100%;

      &.left {
        justify-content: left;
        // margin: -100px 0 200px 0;
        margin: 80px 0 0 0;
        padding-left: 80px;
        padding-right: 40px;
      }

      &.right {
        justify-content: left;
        // margin: -100px 0 200px 0;
        margin: 80px 0 0 0;
        padding-left: 80px;
        padding-right: 40px;
      }
    }
  }

  .tl-icons-line {
    position: absolute;
    left: 50%;
    width: 1px;
    height: 0;
    background-color: #cccccc;

    @media (max-width: 860px) {
      left: 100px;
    }

    @media (max-width: 620px) {
      left: 40px;
    }

    .tl-icon {
      display: flex !important;
      justify-content: center !important;
      align-items: center !important;
      position: absolute;
      border: 1px solid #cccccc;
      border-radius: 100%;
      background: #f5f5f5 !important;
      width: 0;
      height: 0;
      left: 0;
      top: 35px;
      transition: border 0.35s, width 0.35s, height 0.35s, top 0.35s, left 0.35s;
      overflow: hidden;

      svg {
        opacity: 0.25;
        transition: opacity 0.5s;
      }

      &.show {
        width: 70px;
        height: 70px;
        left: -35px;
        top: 0;
      }

      &.dark {
        border: 1px solid #7a7a7a;

        svg {
          opacity: 1;
        }
      }

      @media (max-width: 620px) {
        width: 50px;
        height: 50px;

        &.show {
          width: 50px;
          height: 50px;
          left: -25px;
          top: 0;
        }
      }
    }

    .tl-icons-darkline {
      position: absolute;
      left: 50%;
      top: 0;
      width: 1px;
      height: 0;
      background-color: #7a7a7a;
    }
  }
}
