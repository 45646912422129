.wine__range__container {
  margin: 30px 0 20px;

  @media screen and (min-width: 768px) {
    margin: 50px 0 50px;
  }

  @media screen and (min-width: 1024px) {
    margin: 50px 0 80px;
  }

  p {
    line-height: 140%;
  }
}
