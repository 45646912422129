@import "/resources/scss/theme/base";

.block__f1-quote {

  .content_block {
    max-width: 800px;
    text-align: center;

    .script-heading {
      margin-bottom: rem(30);
      @media only screen and (min-width: $desktop) {
        font-size: rem(65);
        line-height: rem(70);
      }
    }

    .star_rating {
      margin: 0;
      margin-top: 12px;
      padding: 0;
      list-style: none;

      li {
        width: rem(23);
        height: rem(23);
        margin-right: rem(3);
        background-image: url("../resources/icons/star-inactive.svg");
        display: inline-block;
        background-position: center;
        background-repeat: no-repeat;

        &.active {
          background-image: url("../resources/icons/star-active.svg");
        }
      }
    }
  }
}

// Dark mode CSS

.theme-dark {
  [id^="f1-quoteblock_"] {
    background-color: #212121;

    .script-heading {
      color: #FFFFFF;
    }

    p {
      font-size: 12px;
      text-transform: uppercase;
      color: #AD9B5C;
    }
  }
}
