@import "/resources/scss/theme/base";

.block__c2-masthead-landing-pages {
  .block__c2-masthead-landing-pages__row {
    align-items: flex-start;

    .main_heading {
    }

    .content_col_right {
    }

    p {
      margin-bottom: rem(25);
      @media only screen and (max-width: $mobile-landscape) {
        margin-bottom: rem(15);
      }
    }

    .cta_block {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      a + a {
        margin-top: 10px;
      }
      @media only screen and (min-width: $mobile-landscape + 1) {
        flex-direction: row;
        align-items: center;
        a + a {
          margin-top: 0;
          margin-left: 20px;
        }
      }
    }

    .awards {
      display: flex;
      gap: rem(25);
      flex-wrap: wrap;

      img {
        width: calc(25% - 20px);
        @media only screen and (max-width: $mobile-landscape) {
          width: calc(50% - 15px);
        }
      }
    }
  }
}
