.contact-us-form {
  display: flex;
  align-items: center;
  width: 100%;

  .frm_forms {
    min-width: 600px;
  }

  & form {
    & .frm_form_field {
      margin-bottom: 30px !important;
    }

    & .frm_fields_container .frm_submit .frm_button_submit {
      text-transform: uppercase;
      background: #8f1920 !important;
      color: #fff !important;
      font-weight: normal !important;
      font-size: 16px !important;
      padding: 12px 30px !important;
    }

    & label {
      display: none !important;
      color: var(--Black, #222221) !important;
      font-family: Avenir Next !important;
      font-size: 14px !important;
      font-style: normal !important;
      font-weight: 400 !important;
      padding-top: 0px !important;
      padding-bottom: 0px !important;
    }

    & input::placeholder,
    & textarea::placeholder {
      color: black !important;
    }

    & input {
      min-height: unset !important;
    }

    & input,
    & textarea,
    & select {
      border: 0;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      border-radius: 0;
      border-bottom: solid 2px #444 !important;
      box-shadow: none;
      font-size: inherit !important;
      color: #222221 !important;
    }

    & select {
      background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>")
        no-repeat;
      background-position: calc(100% - 0.75rem) center !important;
      -moz-appearance: none !important;
      -webkit-appearance: none !important;
      appearance: none !important;
      padding-right: 2rem !important;
      color: #222221 !important;
    }

    & select option {
      padding: 5px !important;
    }

    & ::placeholder {
      color: black !important;
      opacity: 1;
    }
  }
}

.section-b6 {
  h1 {
    font-size: 5rem;
    font-weight: 400;
    line-height: 5rem;
    margin-bottom: 20px;
  }
  @media screen and (max-width: 767px) {
    h1 {
      font-size: 2.7rem;
      line-height: 3rem;
    }
  }

  @media screen and (max-width: 1199px) {
    h1 {
      font-size: 3.8rem;
      line-height: 3.8rem;
    }
  }
}

.section-b6.bg-img-citrus::after {
  bottom: -10em;
  left: 0;
  transform: rotate(180deg);
  z-index: 2;
}

@media (max-width: 767px) {
  .section-b6-row {
    width: 90%;

    & .section-b6-row-left,
    & .section-b6-row-right {
      width: 100% !important;
    }
  }

  .contact-us-form {
    .frm_forms {
      min-width: 100%;
    }
  }
}

@media (max-width: 1280px) {
  .section-b6-row {
    & .section-b6-row-left {
      align-items: center !important;
    }

    & .section-b6-row-left,
    & .section-b6-row-right {
      width: 100% !important;
      margin: 2rem 0rem;
    }
  }
}

@media (min-width: 1280px) {
  .section-b6 .script-heading {
  }

  .section-b6 .main-heading {
  }

  .section-b6-row {
    width: 80%;
  }
}
