@import "/resources/scss/theme/base";
@import "/resources/scss/theme/functions";

.block__d9-carousel {
  overflow: hidden;

  &[class*="bg-top"] {
    &::after {
      top: 0%;
    }
  }

  &__heading {
    align-items: flex-start;

    &.heading_center {
      align-items: center;
    }
  }

  &__wine-block {
    overflow: hidden;
    position: relative;
    .wine_info {
      max-width: 280px;
      margin: 0 auto;

      p.small {
        font-size: rem(12);
        margin-bottom: rem(6);
        font-weight: 500;
      }
      h5 {
        margin-bottom: rem(8);
      }
    }
  }
}

.splide__slide__container {
  aspect-ratio: 1 / 1;
  position: relative;
  margin: 30px 0 50px;

  &::before {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    content: "";
    background-image: url("../resources/images/product-bg-sash-big.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    opacity: 0;
    transition: opacity 0.5s;
    z-index: 1;
  }

  &:hover::before {
    opacity: 1;
    transition: opacity 0.5s;
  }

  img {
    z-index: 2;
    position: relative;
  }

  &:not(.wine_container) {
    img {
      position: absolute;
      left: 60%;
      top: 0;
      transform: translateX(-50%);
      max-height: 95%;
      transition: left 0.5s;
    }

    img + img {
      left: 40%;
      top: 10%;
      transition: left 0.5s;
    }

    &:hover img {
      left: 63%;
      transition: left 0.5s;
    }

    &:hover img + img {
      left: 37%;
      transition: left 0.5s;
    }
  }
}

@media only screen and (min-width: $mobile-landscape + 1) {
  .splide__track {
    overflow: visible;
  }
}

// Dark mode CSS

.theme-dark {
  [id^="d9-carousel-block_"] {
    background-color: #1c1c1e;

    .script-heading, h5, h2, p {
      color: #FFFFFF;
    }

    .splide__slide:hover a h5 {
        color: #AD9B5C;
    }
  }
}

